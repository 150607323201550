<template>
  <v-app>
    <v-layout row wrap align-center justify-center class="opansans-font ma-0">
      <v-flex xs12 sm12 md6 lg6 xl6 align-center justify-center>
        <v-img :src="loginImg" alt="loginLogo" class="mx-auto" max-width="60%"></v-img>
      </v-flex>
      <v-flex xs12 sm12 md6 lg6 xl6 align-center justify-center>
        <v-row class="ma-0 justify-center">
          <v-card class="pa-6 cardBoxShadow rounded-lg" max-width="380" >
            <!-- <form @keyup.enter="login()">
              <span class="fsize24 themeBlue"> {{ getBrokerName }}</span>
              <div class="fsize22 mb-6 primaryColor">
                {{ getBrokerName }} Admin Dashboard
              </div>
              <div>
                <p class="mb-2 ml-1 fsize14 primaryColor">Username</p>
                <v-text-field v-model.trim="userName" class="fsize11" placeholder="Enter the username"
                  :rules="userNameRules" outlined dense autocomplete></v-text-field>
              </div>
              <div>
                <p class="mb-2 ml-1 fsize14 primaryColor">Password</p>
                <v-text-field v-model="password" placeholder="Enter the password" :rules="passwordRules" class="fsize11"
                  outlined dense :type="passwordShow ? 'text' : 'password'"
                  :append-icon="passwordShow ? 'mdi-eye' : 'mdi-eye-off'" @click:append="passwordShow = !passwordShow"
                  autocomplete></v-text-field>
              </div>
              <v-btn :loading="getLoader" @click="login()" block depressed
                class="login-btn text-capitalize mt-3 primary" :disabled="!userName || !password">
                Sign In</v-btn>
            </form> -->
            <div class="fsize16 mb-2 text-center"><strong>Alice Blue Admin</strong></div>
            <div class="mb-4 d-flex justify-center ">  <img src="../assets/images/antlogo.svg" width="50px" alt=""/> </div>
            <div class="d-flex align-start fsize14">
              <img src="../assets/images/warning_outline.svg" class="mr-2" alt=""> You have successfully logged out. If you wish to continue with login, please click below
            </div>
            <div v-if="error_message" class="fsize14 red--text py-2">{{error_message}}</div>
             <v-btn id="continue_with_web_btn" block depressed @click="ssoRedirect();error_message= null"
                class="login-btn text-capitalize mt-3 primary" >
                Continue with Web</v-btn>
          </v-card>
        </v-row>
      </v-flex>
    </v-layout>
    <footer class="d-flex justify-end ma-4">
      <span class="secondaryColor fsize13">2021 - 2022 Stoneage Solutions. All rights reserved</span>
    </footer>
    <!-- <div class="d-flex align-center justify-center" style="height:100vh !important">
      <img height="60" width="60" src="../assets/Spinner.gif" alt="loading">
    </div> -->
  </v-app>
</template>


<script>
import loginImg from "../assets/loginPic.png";
import { mapGetters } from "vuex";
export default {
  name: "Login",

  data: () => ({
    loginImg: loginImg,
    userName: "",
    password: "",
    passwordShow: false,
    userNameRules: [(v) => !!v || "Username is required"],
    passwordRules: [
      (v) => !!v || "Password is required",
      (v) => (v && v.length >= 6) || "Password must be at least 6 characters",
    ],
  }),

  computed: {
    ...mapGetters(["getBrokerName", "getLoader", "getAuthcode"]),
    error_message : {
      get(){
        return this.$store.state.auth.error_message
      },
      set(val){
        this.$store.commit('auth/setErrorMessage', val)
      }
    }
  },

  created() {
    let uri = window.location.search.substring(1);
    let params = new URLSearchParams(uri);
    console.log(params.get("authCode"));
    let querparams = this.$router.currentRoute.query
    let obj
    if (querparams.authCode && querparams.userId) {
      obj ={
        authCode:querparams.authCode,
        userId:querparams.userId
      }
      this.$store.dispatch("auth/getCustomerLogin",obj);
      
    } else if(querparams.logout){
      this.$store.state.userSeesion && this.$store.state.userID ? this.$router.push('dashboard').catch(()=>{}) : ''
    } else {
      this.ssoRedirect();
    }
  },

  methods: {
    login() {
      // this.$store.dispatch("adminLogin", {
      //   userName: this.userName,
      //   password: this.password,
      // });
    },
    ssoRedirect() {
      window.location.href =
        `https://antaws.aliceblueonline.com?appcode=${this.$store.state.appCode}`;
    },
  }
};
</script>

